@font-face {
  font-family: IBM_Plex_Sans_Bold;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans_Normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans_Medium;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 100;
  font-display: swap;
  font-style: italic;
  src: url('./IBM_Plex_Sans/IBMPlexSans-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 200;
  font-display: swap;
  font-style: normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 200;
  font-display: swap;
  font-style: italic;
  src: url('./IBM_Plex_Sans/IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  src: url('./IBM_Plex_Sans/IBMPlexSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 500;
  font-display: swap;
  font-style: italic;
  src: url('./IBM_Plex_Sans/IBMPlexSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  src: url('./IBM_Plex_Sans/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url('./IBM_Plex_Sans/IBMPlexSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  src: url('./IBM_Plex_Sans/IBMPlexSans-BoldItalic.ttf') format('truetype');
}

$active: var(--active);
$nActive: var(--nActive);
$fff: var(--fff);
$fffBg: var(--fff-bg);
$t1: var(--t1);
$t2: var(--t2);
$t3: var(--t3);
$t4: var(--t4);
$t5: var(--t5);
$t6: var(--t6);
$t7: var(--t7);
$t8: var(--t8);
$t9: var(--t9);
$t10: var(--t10);
$t11 : var(--t11);
$r1: var(--r1);
$f1: var(--f1);
$f2: var(--f2);
$f3: var(--f3);
$f4: var(--f4);
$f5: var(--f5);
$f6: var(--f6);
$f7: var(--f7);
$f8: var(--f8);
$f9: var(--f9);
$f10: var(--f10);
$f11: var(--f11);
$f12: var(--f12);
$f13: var(--f13);
$ft: var(--ft);
$br: var(--br);
$f14: var(--f14);
$f15: var(--f15);
$f16: var(--f16);

/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
$disBtn: var(--disBtn-bg);

/**主背景*/
$bg1: var(--bg1);
/**新设计稿主背景*/
$bg12: var(--bg12);
$bg13: var(--bg13);
$bg14: var(--bg14);
$bg15: var(--bg15);
$bg16: var(--bg16);
$bg17: var(--bg17);

/**次级框背景*/
$bg2: var(--bg2);
/**头部背景*/
$bg3: var(--bg3);
/**线条背景*/
$lineBg1: var(--line-bg);
$lineBg2: var(--line-bg2);
$lineBg3: var(--line-bg3);
$lineBg4: var(--line-bg4);
$lineBg5: var(--line-bg5);
$lineBg6: var(--line-bg6);

$border1: var(--border1);
$border2: var(--border2);
$border3: var(--border3);
$borderLight: var(--border-light);

/**底部栏(固定)背景*/
$footBg: var(--foot-bg);
$spotInputBg: var(--input-bg);
$spotInputBg1: var(--input-bg1);
$searchInputBg: var(--search-input-bg);
$bg4: var(--bg4);
$bg5: var(--bg5);
$bg6: var(--bg6);
$bg7: var(--bg7);
$bg9: var(--bg9);
$bg10: var(--bg10);
$bg11: var(--bg11);
$l1: var(--l1);

$gray1: var(--gray1);

$switchBg: var(--switchBg);
$bar: var(--bar);

$orderBg: var(--order-bg);

// 字体颜色
$mainFont1: var(--mainFont);
$mainFont2: var(--mainFont2);
$mainFont3: var(--mainFont3);
$mainFont4: var(--mainFont4);
$mainFont5: var(--mainFont5);
$mainFont6: var(--mainFont6);
$mainFont7: var(--mainFont7);
$mainFont8: var(--mainFont8);
$mainFont9: var(--mainFont9);
// 合约列表使用
$mainFont10: var(--mainFont10);
$mainFontActive: var(--mainFontActive);
$mainTradeFont: var(--mainFontTxt);
$tradePlaceHolder: var(--tradePlaceHolder);
$tradeClose: var(--tradeClose);

$headerFont2: var(--headerFont);
$fc2: var(--fc2);

$fc5: var(--fc5);
$fc6: var(--fc6);

$lc1: var(--lc1);
$lc2: var(--lc2);
$lc3: var(--lc3);
$lc4: var(--lc4);
$lc5: var(--lc5);
$lc6: var(--lc6);
$rb: var(--rb);
$gb: var(--gb);

$blue: var(--blue);
$ma5: var(--ma5);
$ma10: var(--ma10);
$ma30: var(--ma30);


/***/
$pm: var(--pMFont);
/***/
$pr: var(--pRFont);
/***/
$ps: var(--pSFont);
/**头部tabbar颜色*/
$tabBarColor: var(--tab-bar-color);
/***/
$btnActiveColor: var(--btn-active-color);

$baseWidth: 375;
$baseSize: $baseWidth/10;

$headHeight: 44;

//不同主題的配色變數
$lightPrimary: var(--light-primary);
$lightBg: var(--light-bg);
$lightSecondary: var(--light-secondary);

//new PC Colors
$raise: var(--l-raise);
$fall: var(--l-fall);
$icCtaBlue: var(--ic-cta-blue);
$icCyanBlue: var(--ic-cyan-blue);
$icMintGreen: var(--ic-mint-green);
$icMintShadow: var(--ic-mint-shadow);
$icPurple: var(--ic-purple);
$icPurpleShadow: var(--ic-purple-shadow);
$icWhite: var(--ic-white);
$icWhiteShadow: var(--ic-white-shadow);

$sgGrey1: var(--sg-grey1);
$sgGrey2: var(--sg-grey2);
$sgGrey3: var(--sg-grey3);
$sgGreyDl: var(--sg-greydl);
$sgGreyB: var(--sg-greyb);
$sgGreyC: var(--sg-greyc);

$lPrimary: var(--l-primary);
$lDefault: var(--l-default);
$lDisabled: var(--l-disabled);
$lSuccess: var(--l-success);
$lError: var(--l-error);
$lLink: var(--l-link);
$lWhite: var(--l-white);
$lBlack: var(--l-black);

$bgPrimary: var(--bg-primary);
$bgGradient1: var(--bg-gradient1);
$bgGradient2: var(--bg-gradient2);
$bgGradient3: var(--bg-gradient3);
@mixin background-glass() {
  backdrop-filter: blur(rem(44px));
  -webkit-backdrop-filter: blur(rem(44px));
}

@mixin disable-img-highlight {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

@mixin scrollbar-gone {
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox Chrome */
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
}

$oDarker60: var(--o-darker60);
$oCyanGradient: var(--o-cyan-gradient);
$oPurpleGradient: var(--o-purple-gradient);

@mixin mint-glow() {
  background: #64F18C 0% 0% no-repeat padding-box;;
  opacity: 0.3;
  filter: blur(25px);
}

@mixin cyan-glow() {
  background: transparent radial-gradient(closest-side at 50% 50%, #48CDEE 0%, #48CDEE 0%, #48CDEE00 100%) 0% 0% no-repeat padding-box;;
  opacity: 0.3;
  filter: blur(25px);
}

@mixin glow-gradient() {
  background: transparent linear-gradient(271deg, #0A0C1900 0%, #0A0C19B3 67%, #0A0C19 100%) 0% 0% no-repeat padding-box;
  border-radius: 70px;
  opacity: 1;
}

@mixin scrim-multiply-50() {
  background: transparent linear-gradient(180deg, #171717 0%, #171717 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  border-radius: 70px;
  opacity: 0.5;
}

$eventGold: var(--event-gold);
$eventBlue: var(--event-blue);

//new PC Colors end

@function rem($val) {
  @if unitless($val) == true {
    @return #{$val/$baseSize}rem;
  } @else {
    @return #{($val / ($val * 0 + 1)) / $baseSize}rem;
  }
}
// 为了兼容设计稿是2倍的情况
@function rem2($val) {
  @if unitless($val) == true {
    @return #{$val/$baseSize/2}rem;
  } @else {
    @return #{($val / ($val * 0 + 1)) / $baseSize/2}rem;
  }
}

//英文/数字 字体 通用：
@mixin font-e-family($wight: 500) {
  font-weight: $wight;
}

//中文字体 通用：
@mixin font-z-family($weight: 500) {
  font-weight: $weight;
}

@mixin fontFamily {
  // font-family: DIN Alternate;
}

/**字体*/
@mixin fontDM {
  font-family: 'DIN,DIN-Medium';
}
@mixin fontDB {
   font-family: 'DIN,DIN-Bold';
}
@mixin fontDR {
   font-family: 'DIN,DIN-Regular';
}
@mixin fontPR {
  font-family: $pr;
}
@mixin fontPM {
  font-family: $pm;
}

@mixin fontPS {
  font-family: $ps;
}
@mixin showLoginAni() {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@mixin screen($res-max) {
  @media only screen and (max-width: #{$res-max}px), only screen and (max-device-width: #{$res-max}px) {
    html,
    body {
      font-size: #{($baseSize * ($res-max/$baseWidth))}px !important;
    }
  }
}

@mixin common-button-style() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: default;
  cursor: pointer;
}

@mixin common-button-animation() {
  &:hover {
    box-shadow: 0 2px 3px #f2f2f2;
  }
  &:active {
    box-shadow: 0 2px 3px #ececec inset;
  }
}

@mixin flex-row() {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

@mixin flex-row-align($align: center) {
  @include flex-row();
  justify-content: $align;
}

@mixin flex-column($align: center) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  width: 100%;
}

@mixin flex-column-center() {
  @include flex-column();
  justify-content: center;
}

@mixin submit-button() {
  width: rem(315);
  height: rem(44);
  margin: 0 auto;
}

@mixin common-button() {
  .button {
    width: 100%;
    height: rem(46);
    background: #abbcd2;
    border-radius: rem(3);
    color: $f12;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16);
    transition: all 0.3s;
    margin-top: rem(20);
    pointer-events: none;
  }
  .active {
    background: linear-gradient(to right, $active, $active);
    color: $fff;
    pointer-events: auto;
  }
}

@mixin common-button-css() {
  height: rem(46);
  background: linear-gradient(to right, $active, $active);
  border-radius: rem(3);
  color: $fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(16);
  transition: all 0.3s;
  margin: rem(20px) rem(15px) 0;
  // pointer-events: none;
}

@mixin common-input-container() {
  @include common-input-border();
  display: flex;
  padding-right: rem(10);
}

@mixin common-input() {
  display: block;
  width: 100%;
  padding: rem(10px);
  border: none;
  outline: none;
  font-size: rem(14px);
  background: transparent;
  color: $mainFont1;

  &::placeholder {
    @include font-style(11, 400);
    color: var(--night-placeHolder, $f9);
  }
}

@mixin common-input-border() {
  border: 0.5px solid var(--bc2);
  background: $bg1;
  border-radius: rem(3px);
}

@mixin common-input-row() {
  @include common-input-border();
  display: block;
  width: 100%;
  padding: rem(10px);
  outline: none;
  font-size: rem(14px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  height: rem(42px);
}

@mixin common-input-row-container() {
  display: flex;
  align-items: center;
  background: $bg1;
  border-radius: rem(3);
}

@mixin font-style($size: 16, $wight: 500) {
  font-size: rem($size);
  font-weight: $wight;
}

@mixin font-line-height($size: 16, $wight: 500) {
  font-size: rem($size);
  line-height: rem($size);
  font-weight: $wight;
}

@mixin font-line-height-2($size: 16, $wight: 500,$height:$size) {
  font-size: rem($size);
  line-height: rem($height);
  font-weight: $wight;
}

@mixin number-line-height($size: 16, $wight: 500, $fd: dm) {
  font-size: rem($size);
  line-height: rem($size);
  font-weight: $wight;
  @if ($fd == dm) {
    font-family: "DIN,DIN-Medium";
  } @else if($fd == dr) {
    font-family: "DIN,DIN-Regular";
  } @else if($fd == db) {
    font-family: "DIN,DIN-Bold";
  }
}

@mixin text-overflow-show() {
  white-space: nowrap; /*不允许换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出三点代替*/
}

@mixin form-title() {
  text-align: justify;
  text-align-last: justify;
}

@mixin line-bottom-scale($color: $bg1) {
  position: relative;
  border: none;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid $color;
    transform: scaleY(0.5);
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.5);
    -webkit-transform-origin: 0 0;
  }
}

@mixin line-scale($color: $bg1, $height: 0, $width: 0, $radius: 0) {
  position: relative;
  border: none;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: rem($height);
    width: rem($width);
    border-radius: rem($radius);
    border: 1px solid $color;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform-origin: left top;
    -webkit-transform-origin: left top;
    pointer-events: none;
  }
}

@mixin footer-style($height: 70px) {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  height: rem($height);
  width: 100%;
  background-color: $footBg;
  z-index: 100;
}

@mixin c2c-Choice($imgUrl){
  &::after{
    content: '';
    position: absolute;
    right: rem(-1);
    bottom: rem(-1);
    width: rem(20);
    height: rem(20);
    background: url($imgUrl);
    background-size: 100% 100%;
  }
}

@mixin raise(){
  color: #01A4FF;
}

@mixin fall(){
  color: #F84960;
}

// iphone x/xs/11 pro/12 mini
$device-x: "screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)";
// iphone xr/11
$device-xr: "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)";
// iphone x/xs/11pro max
$device-xmax: "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)";
// iphone 12/12pro
$device-12Pro: "screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)";
// iphone 12proMax
$device-12ProMax: "screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3)";

@media #{unquote($device-x)},#{unquote($device-xr)},#{unquote($device-xmax)},#{unquote($device-12Pro)},#{unquote($device-12ProMax)} {
  .root_include_ru {
    margin-top: rem(40px);
  }

  .footer {
    padding-bottom: rem(10px) !important;
  }
}
