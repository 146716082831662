@import '@/styles/var.module.scss';
$bg1: #ffd140;
$bg2: #181a20;
$bg3: #242730;
$bg4: #1b1e26;

$tc1: #494e5b;
$tc2: #1e2129;
$tc3: #777e90;
$tc4: #ffd140;

$lc1: #777e90;
$lc2: #494e5b;
$lc3: #ffd140;

$lightBlue: #48CDEE;
$newLightBlue: #96D8F5;

@mixin h1-text {
  font-family: IBM_Plex_Sans_Bold;
  font-size: 46px;
  font-weight: 52px;
  color: #E7E9EC;
}

@mixin h2-text {
  font-family: IBM_Plex_Sans_Normal;
  color: #7C7E87;
  font-size: 18px;
  font-weight: normal;
}

.page {
  font-family: IBM_Plex_Sans_Normal;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  // background: #0f1216;
  color: #fff;
  position: relative;
  .pageBackground {
    position: absolute;
    margin: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -100;
    width: 100%;
    height: 100%;
    overflow: hidden; 
  }

  .pageContent {
    width: 100%;
    max-width: calc(1280px);
    margin: auto;
    padding: 15vh 2rem;
    .banner {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      .bannerContent {
        .h1 {
          @include h1-text;
          line-height: 54px;
          margin: 0;
          margin-top: 36px;
        }
        .h2 {
          @include h2-text;
          line-height: 30px;
          margin-top: 20px;
          max-width: 429px;
        }
        .loadingButton {
          width: fit-content;
          display: flex;
          align-items: center;
          margin-top: 30px;
          height: 58px;
          padding: 0 1.2rem;
          cursor: pointer;
          background-color: $lightBlue;
          background: transparent linear-gradient(101deg, #00A5FF 0%, #00CBFD 30%, #00F2ED 100%) 0% 0% no-repeat padding-box;;
          flex: 1;
          border-radius: 50px;
          color: #000000;
          p {
            text-align: center;
            font-size: 16px;
            word-break: keep-all;
            margin: 0px;
          }
          .arrow {
            margin-left: 1rem;
            height: 18px;
            width: 18px
          }
        }
      }
      .bannerImage {
        @include disable-img-highlight;
        // position: absolute;
        width: calc(1120px / 2);
        left: 50%;
        top: -1%;
        z-index: -1;
      }
    }

    .cs {
      position: fixed;
      top: 85%;
      left: 92%;
      border-radius: 50%;
      cursor: pointer;
      z-index: 999;
    }

    .quoteWheel {
      padding-top: 40px;
      width: 100%;
      margin-bottom: 235px;
    }

    .popularInfo {
      margin-bottom: 180px;
      .popularInfotitle {
        .h1 {
          display: block; margin-top: 0.67em; margin-bottom: 0.67em;
          @include h1-text;
        }
      }
      .popularTabTitle {
        display: flex;
        div {
          @include h2-text;
          font-size: 18px;
          cursor: pointer;
          white-space: nowrap;
          margin-right: 2rem;
        }
        .selected {
          color: $lightBlue;
        }
      }
      .popularSpot {
        margin: 2% 0 0 1%;
        width: 95%;
      }
      .market{
        width: 100%;
        margin-top: 5rem;
        // padding: 0 20% 0 0;
      }
    }

    .tradingInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 230px;
      .tradingInfotitle {
        .h1 {
          display: block; font-size: 2em; margin-top: 0.67em; margin-bottom: 0.67em;
          @include h1-text;
        }
        h2 {
          @include h2-text;
          color: #7C7E87;
        }
        .tradingInfoData {
          margin: 40px 0;
          display: flex;
          width: 100%;
          text-align: left;
          gap: 108px;
          p {
            margin: 0;
          }
          .data {
            font-size: 36px;
            color: $newLightBlue;
            margin-bottom: 10px;
          }
          .label {
            font-size: 14px;
            font-weight: 23px;
            color: #7C7E87;
          }
        }
        .loadingButton {
          width: fit-content;
          display: flex;
          align-items: center;
          margin-top: 30px;
          height: 58px;
          padding: 0 1.2rem;
          cursor: pointer;
          background-color: $lightBlue;
          background: transparent linear-gradient(101deg, #00A5FF 0%, #00CBFD 30%, #00F2ED 100%) 0% 0% no-repeat padding-box;;
          flex: 1;
          border-radius: 50px;
          color: #000000;
          p {
            text-align: center;
            font-size: 16px;
            word-break: keep-all;
            margin: 0px;
          }
          .arrow {
            margin-left: 1rem;
            height: 18px;
            width: 18px
          }
        }
      }
      .tradingInfoImage {
        @include disable-img-highlight;
        width: 383px;
      }
    }

    .trustInfo {
      max-width: 1080px;
      margin: auto;
      .trustInfotitle {
        .h1 {
          display: block; font-size: 2em; margin-top: 0.67em; margin-bottom: 0.67em;
          @include h1-text;
          white-space: nowrap;
          text-align: center;
        }
      }
      .security,
      .protection {
        background-color: #020411;
        border-radius: 3rem;
        width: 500px;
        padding: 1rem 3rem;
        transition: box-shadow 0.15s ease-in-out;
        .securityText,
        .protectionText {
          h2 {
            height: 75px;
            font-family: IBM_Plex_Sans_Medium;
          }
          p {
            color: #7C7E87;
          }
        }
      }
      .securityImage,
      .protectionImage {
        span {
          height: 50px !important;
          width: 50px !important; 
        }
        margin: 25px 0;
      }
      .trustInfoContent {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        h2 {
          @include h2-text;
          margin: 0 0 3rem 0;
          font-size: 30px;
          color: #E7E9EC;
        }
        p {
          font-size: 16px;
          line-height: 2rem;
        }
      }
    }

    .downloadCon {
      margin: 165px 0;
    }

    .startEarning {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .startEarningLeftIcon, .startEarningRightIcon {
        @include disable-img-highlight;
        position: absolute;
        top: 0;
        width: 30%;
        min-width: 200px;
        z-index: -1;
      }
      .startEarningLeftIcon {
        top: -10%;
        left: -2%;
      }
      .startEarningRightIcon {
        width: 22%;
        min-width: 120px;
        right: -2%;
      }
      .startEarningtitle {
        width: 100%;
        align-items: center;
        text-align: center;
        .h1 {
          display: block; font-size: 2em; margin-top: 0.67em; margin-bottom: 0.67em;
          @include h1-text;
          text-align: center;
          color: #EEEEEE;

        }
        .loadingButton {
          width: fit-content;
          margin: auto;
          display: flex;
          align-items: center;
          margin-top: 30px;
          height: 58px;
          padding: 0 1.2rem;
          cursor: pointer;
          background-color: $lightBlue;
          background: transparent linear-gradient(101deg, #00A5FF 0%, #00CBFD 30%, #00F2ED 100%) 0% 0% no-repeat padding-box;;
          flex: 1;
          border-radius: 50px;
          color: #000000;
          p {
            text-align: center;
            font-size: 16px;
            word-break: keep-all;
            margin: 0px;
          }
          .arrow {
            margin-left: 1rem;
            height: 18px;
            width: 18px
          }
        }
      }
    }
  }
}

.loadingButton2 {
  display: none;
}

.smallDownloadPanel {
  display: none;
}

@media screen and (max-width: 768px) {
  .page {
    .pageContent {
      padding-top: 70px;
      padding-bottom: 50px;
      .banner {
        flex-direction: column-reverse;
        text-align: center;
        padding-top: 0;
        .bannerImage {
          width: 247px;
        }
        .bannerContent {
          .h1 {
            font-size: 27px;
            line-height: 35px;
            margin-top: 12px;
          }
          .h2 {
            font-size: 15px;
            line-height: 21px;
            margin-top: 30px;
          }
          .loadingButton {
            margin: 32px auto;
            min-height: 42px;
            height: 100%;
            p {
              font-size: 15px;
            }
            .arrow {
              display: none;
            }
          }
        }
        .smallDownloadPanel {
          z-index: 999;
          display: block;
          position: fixed;
          width: 100%;
          bottom: 0%;
          animation: moveUp 1s ease-in;
          .smallDownload {
            position: relative;
            background: linear-gradient(to right, rgba(18, 20, 28, 0.8) 0%, rgba(18, 20, 28, 0.8) 50%, rgba(0, 0, 0, 0.1) 100%);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            height: 80px;
            border-radius: 6px;
            .cross {
              position: absolute;
              top: 0;
              right: 2%;
              cursor: pointer;
            }
            .content {
              font-family: IBM_Plex_Sans_Medium;
              color: #EEEEEE;
              padding: 0 12.78px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100%;
              .contentLeft {
                width: 100%;
                display: flex;
                align-items: center;
                svg {
                  width: 62px;
                }
                .contentUpDown {
                  margin: 0 15px;
                  text-align: left;
                  .contentUp {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
              .contentRight {
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 34px;
                margin-right: 12px;
                padding: 5px 10px;
                border: #EEEEEE 1px solid;
                border-radius: 8px;
                font-size: 14px;
                white-space: nowrap;
              }
            }
          }
        }
      }
      .quoteWheel {
        padding-top: 20px;
        margin-bottom: 124px;
      }
      .popularInfo {
        margin-bottom: 124px;
        .popularInfotitle {
          .h1 {
            font-size: 27px;
          }
        }
        .popularSpot {
          margin: 0;
        }
        .popularTabTitle {
          margin: 35px 0;
          div {
            font-size: 15px;
          }
        }
        .market {
          margin-top: 2rem;
        }
      }
      .tradingInfo {
        flex-direction: column;
        margin-bottom: 124px;
        .tradingInfotitle {
          .h1 {
            font-size: 27px;
          }
          h2 {
            font-size: 15px;
            margin: 30px 0 35px 0;
          }
          .tradingInfoData {
            margin: 0;
            justify-content: space-between;
            text-align: center;
            gap: 0;
            .data {
              font-size: 17px;
              font-family: IBM_Plex_Sans_Medium;
            }
            .label {
              font-size: 12px;
            }
          }
          .loadingButton {
            margin: 52px auto;
            height: 100%;
            min-height: 42px;
            p {
              font-size: 15px;
            }
            .arrow {
              display: none;
            }
          }
        }
        .tradingInfoImage {
          margin: auto;
          width: 268px;
        }
      }
      .trustInfo {
        width: 100%;
        margin-bottom: 124px;
        .trustInfotitle {
          text-align: center;
          margin-bottom: 60px;
          .h1 {
            white-space: normal;
            font-size: 27px;
          }
        }
        .trustInfoContent {
          flex-direction: column;
          align-items: center;
          gap: 0px;
          .security, .protection {
            border-radius: 0;
            width: 100%;
            padding: 0rem;
            display: flex;
            background-color: transparent;
            .securityImage,
            .protectionImage {
              span {
                height: 30px !important;
                width: 30px !important;
              }
              margin: 5px 16px 0 0;
            }
            .securityText,
            .protectionText {
              h2 {
                margin: 0 0 19px 0;
                font-size: 17px;
                line-height: 24px;
                height: fit-content;
              }
              p {
                font-size: 15px;
                line-height: 21px;
                margin-bottom: 39px;
              }
            }
          }
          .security {
            border-radius: 20px 20px 0 0;
          }
          .protection {
            border-radius: 0 0 20px 20px;
          }
        }
      }
      .downloadCon {
        margin-bottom: 60px;
      }
      .startEarning {
        .startEarningtitle {
          .h1 {
            font-size: 17px;
          }
          .loadingButton {
            min-height: 42px;
            margin-top: 14px;
            height: 100%;
            p {
              font-size: 15px;
            }
            .arrow {
              display: none;
            }
          }
        }
        .startEarningLeftIcon {
          top: 0;
          left: -22%;
          min-width: 160px;
        }
        .startEarningRightIcon {
          right: -12%;
        }
      }
    }
  }
}

/* galaxy fold sb */
@media screen and (max-width: 280px) {
  .page {
    .pageContent {
      .banner{
        .smallDownloadPanel {
          .smallDownload {
            padding: 10px 0;
            height: fit-content;
            .content {
              flex-direction: column;
              .contentRight {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes moveUp {
  0% {
    bottom: -20%;
  }

  100% {
    bottom: 3%;
  }
}